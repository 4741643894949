import React from "react"
import UtilityBox from "../components/UtilityBox"
import { H1, H2 } from "../components/elements/Headings"
import {
  PageTitleContainer,
  UtilityBoxContainer,
} from "../components/layout/Containers"
import SEO from "../components/seo"
import Layout from "../components/layout/Layout"

const Home = () => {
  return (
    <>
      <SEO title="Home" />
      <Layout>
        <PageTitleContainer>
          <H1>Utilities</H1>
          <H2>All the utilities available</H2>
        </PageTitleContainer>
        <UtilityBoxContainer>
          <UtilityBox
            key={"base64"}
            path="/base64"
            name="BASE64"
            description="All the base64 utilities you'll ever need, EVER!"
          />
          <UtilityBox
            key={"string"}
            path="/string"
            name="String"
            description="Some String utilities to make them prettier 🙂"
          />
          <UtilityBox
            key={"date"}
            path="/date"
            name="Date"
            description="Most USEFUL utilities you'll ever find on the Internet"
          />
        </UtilityBoxContainer>
      </Layout>
    </>
  )
}

export default Home
